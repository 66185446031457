import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { lighten } from 'polished'
import React, { useContext, useRef } from 'react'
import { PlayCircle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { useExplainerVideo } from '../../hooks/useExplainerVideo'
import AnimatedWave from '../AnimatedWave'
import { Button } from '../Button'
import Container from '../Container'

export default () => {
  const themeContext = useContext(ThemeContext)
  const { controls, video, state } = useExplainerVideo()
  const hasClickedPlay = useRef(false)

  const handlePlay = () => {
    if (!hasClickedPlay.current) {
      trackCustomEvent({
        category: 'Explainer Video',
        action: 'Play',
        label: 'Home Page - daisee.com/'
      })

      hasClickedPlay.current = true
      controls.play()
      return
    }

    controls.play()
  }


  return (
    <Main>
      <Inner>
        <Container>
          <h1>Hear between the lines</h1>
          <Grid>
            <CopyWrapper>
              <h2>daisee gives you a deeper understanding of your customer interactions.</h2>
              <p>
                Manage your agents from one place, enhance productivity, rapidly transcribe calls, evaluate performance,
                and improve quality assurance standards.
              </p>
              <ButtonWrapper>
                <Link to='/product/'>
                  <Button btnHeight='auto' type='button' bgColor={themeContext.colors.green} action='see the product' />
                </Link>
              </ButtonWrapper>
            </CopyWrapper>

            <VideoWrapper onClick={handlePlay}>
              {video}
              <SvgWrapper style={{ display: state.playing ? 'none' : 'block' }}>
                <PlayCircle color='#1ED760' size={56} strokeWidth={1} />
              </SvgWrapper>
            </VideoWrapper>
          </Grid>
        </Container>
      </Inner>
      <WaveWrapper>
        <AnimatedWave />
      </WaveWrapper>
    </Main>
  )
}

const SvgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const WaveWrapper = styled.div`
  display: flex;
  width: 100vw;
  align-items: flex-end;
  height: 3rem;
  pointer-events: none;

  svg {
    width: 100%;
    pointer-events: none;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 48em) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

const CopyWrapper = styled.div`
  display: grid;
  gap: 2rem;
  padding-right: 2rem;
  height: fit-content;
`

const ButtonWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  max-width: 6rem;
`

const VideoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-bottom: 56.25%;
  width: 100%;
  cursor: pointer;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Inner = styled.div`
  padding: 5rem 0;
`

const Main = styled.div`
  margin-top: 6rem;
  color: #fff;

  h1,
  h2 {
    font-weight: 400;
    margin: 0;
  }

  h1 {
    line-height: 1.1;
    margin-bottom: 2rem;
  }

  h2 {
    line-height: 1.3;
  }

  background: ${({ theme }) =>
    `linear-gradient(101deg,${lighten(0.2, theme.colors.purple)} 0%, ${theme.colors.purple} 100%)`};
`
