import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useVideo } from 'react-use'

const defaultProps = {
  preload: 'metadata',
  autoPlay: false,
  controls: true,
  playsInline: true,
  src: 'https://res.cloudinary.com/harrybu/video/upload/v1635460635/Daisee_Explainer_Video_2_with_subtitles_1_uh2bpb.mp4'
}

export const useExplainerVideo = (props?: any) => {
  const poster = useStaticQuery(graphql`
  query Poster {
    file(relativePath: { eq: "video_thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  const [video, state, controls] = useVideo(
    <video
      poster={poster.file.childImageSharp.fluid.src}
      {...defaultProps}
      {...props}
    />
  )

  return { video, state, controls }
}