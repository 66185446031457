import React, { useContext } from 'react';
import { lighten } from 'polished';
import styled, { keyframes, ThemeContext } from 'styled-components';

export default () => {
  const themeContext = useContext(ThemeContext);

  return (
    <svg
      className="editorial"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 20 140 20"
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0
    58-18 88-18s
    58 18 88 18
    58-18 88-18
    58 18 88 18
    v44h-352z"
        />
      </defs>
      <G>
        <use xlinkHref="#gentle-wave" x="50" y="0" fill={lighten(0.1, themeContext.colors.purple)} opacity="0.9" />
        <use xlinkHref="#gentle-wave" x="50" y="4" fill={lighten(0.05, themeContext.colors.purple)} opacity="0.8" />
        <use xlinkHref="#gentle-wave" x="50" y="6" fill={themeContext.colors.purple} opacity="0.7" />
      </G>
    </svg>
  );
};

const AnimatedWaves = keyframes`
  0%{transform: translate3d(-90px , 0%, 0px)}
  100%{transform: translate3d(85px , 0%, 0px)}
`;

const G = styled.g`
  use {
    animation: ${AnimatedWaves} 24s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
    &:nth-child(2) {
      animation-delay: -2s;
      animation-duration: 5s;
    }
    &:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 3s;
    }
  }
`;
