import { Link, graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import Container from '../Container'

interface Data {
  publicURL: string
}

enum CaseStudy {
  MYOB,
  EMPLOYSURE
}

export default () => {
  const data = useStaticQuery(graphql`
    query Success {
      myob: file(relativePath: { eq: "customerStories/myob.svg" }) {
        publicURL
      }
      myobImg: file(relativePath: { eq: "successPancake/myob.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      employsure: file(relativePath: { eq: "customerStories/employsure.svg" }) {
        publicURL
      }
      employsureImg: file(relativePath: { eq: "successPancake/employsure.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [selected, setSelected] = useState<CaseStudy>(CaseStudy.MYOB)

  const Myob = () => {
    return (
      <Case>
        <CaseStudyWrapper>
          <Img fluid={data.myobImg.childImageSharp.fluid} alt='myob' />
        </CaseStudyWrapper>
        <CaseStudyCopyWrapper>
          <CaseStudyCopyHeadline>
            MYOB increased sales conversion and CSAT score by 22% with insights from daisee
          </CaseStudyCopyHeadline>
          <StyledLink to='customers/stories/myob/'>Read the case study</StyledLink>
        </CaseStudyCopyWrapper>
        <CaseStudyStatisticWrapper>
          <CaseStudyStatistic>22%</CaseStudyStatistic>
          <CaseStudyStatisticSubcopy>Increase in customer satisfaction</CaseStudyStatisticSubcopy>
        </CaseStudyStatisticWrapper>
      </Case>
    )
  }

  const Employsure = () => {
    return (
      <Case>
        <CaseStudyWrapper>
          <StyledImg fluid={data.employsureImg.childImageSharp.fluid} alt='employsure' />
        </CaseStudyWrapper>
        <CaseStudyCopyWrapper>
          <CaseStudyCopyHeadline>
            Employsure retained customers and increased compliance with daisee.
          </CaseStudyCopyHeadline>
          <StyledLink to='customers/stories/employsure/'>Read the case study</StyledLink>
        </CaseStudyCopyWrapper>
        <CaseStudyStatisticWrapper>
          <CaseStudyStatistic>100%</CaseStudyStatistic>
          <CaseStudyStatisticSubcopy>Of calls analysed and evaluated</CaseStudyStatisticSubcopy>
        </CaseStudyStatisticWrapper>
      </Case>
    )
  }

  const handleSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.currentTarget as HTMLButtonElement

    if (element instanceof HTMLButtonElement && element.dataset.item !== undefined) {
      switch (element.dataset.item) {
        case CaseStudy.MYOB.toString():
          return setSelected(CaseStudy.MYOB)
        case CaseStudy.EMPLOYSURE.toString():
          return setSelected(CaseStudy.EMPLOYSURE)
        default:
          return setSelected(CaseStudy.MYOB)
      }
    }

    return undefined
  }

  const getStudy = () => {
    switch (selected) {
      case CaseStudy.MYOB:
        return Myob()
      case CaseStudy.EMPLOYSURE:
        return Employsure()
      default:
        return Myob()
    }
  }

  return (
    <>
      <PancakeWrapper>
        <Container>
          <SuccessBanner>
            <Inner>
              <Heading>
                <Column
                  selected={selected === CaseStudy.MYOB}
                  data-item={CaseStudy.MYOB}
                  onClick={e => handleSelect(e)}
                  type='button'
                >
                  <SuccessImg src={data.myob.publicURL} alt='myob' />
                </Column>
                <Column
                  selected={selected === CaseStudy.EMPLOYSURE}
                  data-item={CaseStudy.EMPLOYSURE}
                  onClick={e => handleSelect(e)}
                  type='button'
                >
                  <SuccessImg src={data.employsure.publicURL} alt='employsure' />
                </Column>
              </Heading>
              <Main>{getStudy()}</Main>
            </Inner>
          </SuccessBanner>
        </Container>
      </PancakeWrapper>
    </>
  )
}

interface ImgProps {
  fluid: FluidObject
}

const StyledImg = styled(Img)<ImgProps>`
  height: fit-content;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.purple};
`

const Case = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
  grid-template-rows: max-content;

  @media all and (max-width: 48em) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`

const CaseStudyWrapper = styled.div`
  width: 15rem;
  position: relative;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 25%;
    cursor: pointer;
  }

  @media all and (max-width: 26.563em) {
    width: auto;
  }
`

const CaseStudyCopyWrapper = styled.article`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
`

const CaseStudyCopyHeadline = styled.h4`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  max-width: 30ch;

  @media all and (max-width: 26.563em) {
    font-size: 1.25rem;
  }
`

const CaseStudyStatisticWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  grid-template-rows: min-content;
`

const CaseStudyStatistic = styled.p`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.purple};
  line-height: 1;
  font-weight: 500;
`

const CaseStudyStatisticSubcopy = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey700};
  text-transform: uppercase;
  letter-spacing: 1px;
`

interface StyledImgProps {
  fluid: {}
}

const SuccessImg = styled.img``

interface ColumnProps {
  selected: boolean
}

const Column = styled.button<ColumnProps>`
  background: ${({ theme }) => theme.colors.grey200};
  flex: 1 1 0;
  border-radius: 60px;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  padding: 0.5rem 3rem;
  max-height: 5rem;

  @media all and (max-width: 31.25em) {
    padding: 0.5rem 2rem;
  }

  img {
    max-width: 120px;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`

const SuccessBanner = styled.article`
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 8px;
  position: relative;
  z-index: 5;
  background: #fff;
`

const Inner = styled.div`
  padding: 1.5rem;
  display: grid;
  gap: 3rem;
  grid-auto-flow: row;
`

const Heading = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  @media all and (max-width: 48em) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: 23.4375em) {
    grid-template-columns: 1fr;
    max-width: 200px;
  }
`

const PancakeWrapper = styled.div`
  padding: 6rem 1.5rem;
`

const Main = styled.main``
