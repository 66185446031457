import { darken } from 'polished'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import HeroImg from '../../assets/mockup_redacted.svg'
import Container from '../Container'
import Divider from '../Divider'

export default () => {
  const themeContext = useContext(ThemeContext)
  return (
    <>
      <Section bgColor={themeContext.colors.purple}>
        <Container>
          <CopyGrid>
            <Panel>
              <PanelInner>
                <CopyWrapper>
                  <Tag>Quality Assurance</Tag>
                  <H2 textColor='#fff'>Analyse 100% of calls and customer interactions</H2>
                  <Copy invert textColor='#fff'>
                    daisee augments human intelligence by automatically revealing compliance gaps, caller sentiment, and
                    the underlying reasons why customers are contacting your business.
                  </Copy>
                </CopyWrapper>
              </PanelInner>
            </Panel>
            <ImageWrapper>
              <StyledImg src={HeroImg} alt='daisee user interface' />
            </ImageWrapper>
          </CopyGrid>
        </Container>
      </Section>
      <Divider topColor={themeContext.colors.purple} botColor='#fff' />
      <Container>
        <CopyGrid>
          <VideoWrapper>
            <video
              preload='none'
              autoPlay
              playsInline
              muted
              poster='https://res.cloudinary.com/harrybu/video/upload/v1605754922/benefit_revenue_w6us2h.jpg'
              loop
            >
              <source
                src='https://res.cloudinary.com/harrybu/video/upload/v1605754922/benefit_revenue_w6us2h.mp4'
                type='video/mp4'
              />
              Your browser does not support the video tag.
            </video>
          </VideoWrapper>
          <Panel>
            <PanelInner>
              <CopyWrapper>
                <Tag>Revenue Generation</Tag>
                <H2>Accelerate your revenue with AI-driven actionable insights</H2>
                <Copy invert>
                  A meticulously crafted customer experience naturally leads to increased customer satisfaction and
                  revenue growth. <br />
                  <br />
                  daisee utilises Artificial Intelligence, advanced natural language processing, and sentiment analysis
                  to allow you to gain deep insight into the reasons customers are calling. Our solution identifies
                  emotions, reveals instances of non-compliance, and ultimately helps your business operate one step
                  ahead of both your competition and customer base.
                </Copy>
              </CopyWrapper>
            </PanelInner>
          </Panel>
        </CopyGrid>
        <CopyGrid>
          <Panel>
            <PanelInner>
              <CopyWrapper>
                <Tag>Agent Productivity</Tag>
                <H2>Boost agent productivity and empower your team</H2>
                <Copy invert>
                  Operational productivity is one of the core driving factors of any business. daisee surfaces AI-driven
                  insights about your team&apos;s performance so you can ensure high-value interactions are provided by
                  all of your agents.
                </Copy>
              </CopyWrapper>
            </PanelInner>
          </Panel>
          <VideoWrapper>
            <video
              preload='none'
              autoPlay
              playsInline
              muted
              poster='https://res.cloudinary.com/harrybu/video/upload/v1605689038/benefit_productivity_gkg25i.jpg'
              loop
            >
              <source
                src='https://res.cloudinary.com/harrybu/video/upload/v1605689038/benefit_productivity_gkg25i.mp4'
                type='video/mp4'
              />
              Your browser does not support the video tag.
            </video>
          </VideoWrapper>
        </CopyGrid>
      </Container>
    </>
  )
}

const VideoWrapper = styled.div`
  height: 0;
  top: 0;
  padding-bottom: 75%;
  position: relative;
  height: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

const Tag = styled.p`
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey400};
  user-select: none;
`

interface CopyProps {
  textColor?: string
  invert?: boolean
}

const Copy = styled.p<CopyProps>`
  color: ${({ theme, textColor }) => (typeof textColor === 'string' ? textColor : theme.colors.grey700)};
  font-size: 1.125rem;
  max-width: 50ch;
  line-height: 1.7;
  position: relative;
  padding-left: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: ${({ theme, invert }) => (invert ? darken(0.05, theme.colors.green) : theme.colors.green)};
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  @media all and (max-width: 26.563em) {
    font-size: 0.875rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
`

interface H2Props {
  textColor?: '#fff'
}

const H2 = styled.h2<H2Props>`
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.3;
  text-transform: capitalize;
  color: ${({ theme, textColor }) => (typeof textColor === 'string' ? textColor : theme.colors.grey900)};
`

const StyledImg = styled.img`
  box-shadow: ${({ theme }) => theme.boxShadow.large};
  margin-bottom: 1.5rem;
`

const CopyGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 0;
  gap: 1.5rem;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }
`

interface SectionProps {
  bgColor?: string
}

const Section = styled.section<SectionProps>`
  background: ${({ bgColor }) => bgColor};
  position: relative;
  z-index: 5;
`

interface PanelProps {
  switchPos?: boolean
}

const Panel = styled.div<PanelProps>`
  border-radius: 4px;
  position: relative;
  color: #fff;
  @media all and (max-width: 48em) {
    margin-top: 3rem;
    grid-row: 1;
  }
`

const PanelInner = styled.div`
  padding: 0 1.5rem;

  @media all and (max-width: 48em) {
    padding: 0;
  }
`

const CopyWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-rows: max-content;
`
