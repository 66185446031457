import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../typescript/types';
import { getLetterSpacingFromRem } from '../utils/getLetterSpacingFromRem';

interface HeadingProps {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: Color;
  fontSizes?: [string, string, string];
  fontWeight?: number;
  psuedoColor?: Color;
  lineHeight?: number;
  letterSpacing?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

const Heading: React.FC<HeadingProps> = ({
  tag,
  children,
  color = 'black',
  fontSizes = ['2rem', '2.5rem', '3rem'], // mobile, tablet, desktop
  psuedoColor,
  fontWeight = 500,
  lineHeight = 1,
  textAlign = 'left',
}) => (
  <H1
    as={tag}
    tag={tag}
    color={color}
    fontSizes={fontSizes}
    fontWeight={fontWeight}
    psuedoColor={psuedoColor}
    lineHeight={lineHeight}
    textAlign={textAlign}
  >
    {children}
  </H1>
);

const pseudoEl = css<HeadingProps>`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: ${({ theme, psuedoColor }) => theme.colors[psuedoColor!]};
    height: 4px;
    width: 3rem;
  }
`;

const typography = css<HeadingProps>`
  font-size: ${({ fontSizes }) => fontSizes![2]};
  letter-spacing: ${({ fontSizes }) => getLetterSpacingFromRem(fontSizes![2])};

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: ${({ fontSizes }) => fontSizes![1]};
    letter-spacing: ${({ fontSizes }) => getLetterSpacingFromRem(fontSizes![1])};
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    font-size: ${({ fontSizes }) => fontSizes![0]};
    letter-spacing: ${({ fontSizes }) => getLetterSpacingFromRem(fontSizes![0])};
  }
`;

const H1 = styled.h1<HeadingProps>`
  position: relative;
  margin: 0;
  ${typography}
  color: ${({ color, theme }) => theme.colors[color!]};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlign }) => textAlign};
  ${({ psuedoColor }) => psuedoColor && pseudoEl}
`;

export default Heading;
