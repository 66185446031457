import React from 'react';
import styled from 'styled-components';

interface Props {
  topColor: string;
  botColor: string;
}

export default ({ topColor, botColor }: Props) => {
  return (
    <DividerWrapper>
      <Svg
        preserveAspectRatio="none"
        width="100%"
        height="190"
        viewBox="0 0 1440 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path d="M0 0h1440v190H0V0z" fill={botColor} />
          <path d="M1440 95.49c-650 149.999-790-150-1440 0V0h1440v95.49z" fill={topColor} />
          <path
            d="M1951.29 190V95.49c-325 74.999-522.5 37.5-720 0s-394.997-75-719.998 0V190H1951.29z"
            fill={botColor}
            fillOpacity=".5"
          />
          <path
            d="M482 190V95.49c324.999 74.999 522.5 37.5 720 0s395-75 720 0V190H482z"
            fill={botColor}
            fillOpacity=".25"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill={topColor} d="M0 0h1440v190H0z" />
          </clipPath>
        </defs>
      </Svg>
    </DividerWrapper>
  );
};

const DividerWrapper = styled.div`
  position: relative;
  z-index: 6;
  margin: -1.5rem 0;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const Svg = styled.svg`
  width: 100%;
  margin-top: -4px;
`;
