import { Link } from 'gatsby';
import { sampleSize } from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { points } from '../../constants/points';
import { Button } from '../Button';
import Container from '../Container';
import Points from '../Points';

const headings = [
  "Let's get started",
  'Discover the power of daisee today',
  "daisee's AI gives your business the competitive edge",
];

export default () => {
  const themeContext = useContext(ThemeContext);
  const heading = useMemo(() => sampleSize(headings, 1), [headings]);

  return (
    <CTA>
      <Container>
        <CTAContainer>
          <CTAHeading>{heading}</CTAHeading>
          <Link to="/start-a-demo/">
            <Button bgColor={themeContext.colors.green} action="start a demo" btnHeight="auto" />
          </Link>
          <KeyPoints>
            <Points quantity={3} points={points} />
          </KeyPoints>
        </CTAContainer>
      </Container>
    </CTA>
  );
};

const KeyPoints = styled.div`
  display: grid;
  gap: 1.5rem;
  padding: 3rem 0;
`;

const CTA = styled.article`
  background: ${({ theme }) => theme.colors.grey200};
  padding: 6rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CTAHeading = styled.h3`
  padding-bottom: 3rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  @media all and (max-width: 28.125em) {
    font-size: 1.5rem;
  }
`;
