import { Link } from 'gatsby';
import { lighten, rgba } from 'polished';
import React, { useContext } from 'react';
import { Cpu, TrendingUp, UserCheck } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';
import Container from '../Container';
import { Color } from '../utils/getColor';

import StatisticWave from '../../assets/statisticsWave.svg';
import Heading from '../Heading';
import { Wrapper } from '../TrustedPartners';

const Statistics: React.FC = () => {
  const themeContext = useContext(ThemeContext);

  const statistics = [
    {
      figure: '90% +',
      copy: 'Accuracy in transcription and quality scoring',
      icon: <Cpu size={42} color={themeContext.colors.purple} />,
      reference:
        '* As verified through comparing a control group of human scores against the word error rate versus the ground truth assessment undertaken by daisee',
    },
    {
      figure: '300%',
      copy: 'See up to 300% return on investment in 12 months',
      icon: <TrendingUp size={42} color={themeContext.colors.purple} />,
      reference:
        "* Based on the average productivity uplift of daisee customers' contact centre agents and QA teams, and a conservative estimate of a 1% increase in revenue.",
    },
    {
      figure: '22%',
      copy: 'Increase in customer satisfaction (CSAT). Discover results immediately',
      icon: <UserCheck size={42} color={themeContext.colors.purple} />,
      reference: '* As reported by MYOB after integrating daisee in their contact centre workflow',
      link: '/customers/stories/myob',
    },
  ];

  return (
    <StyledStatistics id="statisticGrid">
      <Container>
        <Wrapper large>
          <Heading fontSizes={['1.5rem', '1.75rem', '2.5rem']} textAlign="center" tag="h2">
            The best AI speech analytics software on the market.
          </Heading>
        </Wrapper>
        <CardsContainer>
          <StatisticGrid>
            {statistics.map((el, index) => (
              <Card key={el.copy}>
                <Inner>
                  <IconWrapper>{el.icon}</IconWrapper>
                  <Figure>
                    <span>{el.figure}</span>
                    <Sub>*</Sub>
                  </Figure>
                  <FigureCopy>{el.copy}</FigureCopy>
                  {el.link ? (
                    <Link to={el.link} className={`referrence-${index}`}>
                      <Reference className={`referrence-${index}`}>{el.reference}</Reference>
                    </Link>
                  ) : (
                    <Reference className={`referrence-${index}`}>{el.reference}</Reference>
                  )}
                </Inner>
              </Card>
            ))}
          </StatisticGrid>
          <Wave src={StatisticWave} alt="daisee wave" />
        </CardsContainer>
      </Container>
    </StyledStatistics>
  );
};

const Sub = styled.sub`
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => lighten(0.2, theme.colors.purple)};
`;

const Reference = styled.span`
  color: ${({ theme }) => lighten(0.2, theme.colors.purple)};
  font-size: 0.875rem;
  font-weight: 500;
  opacity: 0;
  transition: all 0.5s ease;
  margin-top: -6rem;
`;

const CardsContainer = styled.div`
  position: relative;
`;

const Wave = styled.img`
  position: absolute;
  z-index: 1;
  top: 20%;
`;

const FigureCopy = styled.p`
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grey700};
  text-align: center;
  position: relative;
  background: white;
  z-index: 2;
  padding: 1.5rem 0;
`;

const StatisticGrid = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem 1.5rem 8rem;
  gap: 3rem;
  position: relative;
  z-index: 2;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
  }
`;

const Inner = styled.div`
  padding: 3rem 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: max-content;

  a {
    transition: all 0.3s ease;
    margin-top: -6rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: min-content;
  margin: 0 auto;
  background: ${({ theme }) => rgba(theme.colors.purple, 0.05)};
  padding: 1.5rem;
  border-radius: 50%;
`;

interface CardProps {
  trimColor?: Color;
}

const Card = styled.div<CardProps>`
  background: #fff;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  border-radius: 4px;
  position: relative;

  &:hover {
    .referrence-0,
    .referrence-1,
    .referrence-2 {
      opacity: 1;
      margin-top: 0;
    }
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.medium};
  }

  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.purple};
    width: 100%;
    height: 4px;
    top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

const StyledStatistics = styled.section`
  position: relative;
  z-index: 1;
  :before {
    content: '';
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
  }

  @media all and (max-width: 52.5em) {
    height: auto;
  }
`;

const Figure = styled.h3`
  font-size: 3rem;
  letter-spacing: -1px;
  text-align: center;
  font-weight: 600;
  position: relative;
  padding: 1.5rem 0;
  background: #fff;
  line-height: 1;
  z-index: 2;
`;

export default Statistics;
