import React, { useState, useEffect } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import styled from 'styled-components'
import Container from '../Container'
import { customers } from '../../constants/customers'
import { partners } from '../../constants/partners'
import { ICompany } from '../../typescript/interfaces'

type Props = {
  className?: string
}

const TrustedParnters: React.FC<Props> = ({ className }) => {
  const [logos] = useState<ICompany[][]>([[...partners], [...customers]])
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (counter >= 1) {
        setCounter(0)
      } else {
        setCounter(counter + 1)
      }
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [counter])

  return (
    <StyledTrustedPartners className={className}>
      <Container>
        <Wrapper>
          <Heading>
            Trusted By Our
            <br />
            <br />{' '}
            <SwitchTransition>
              <CSSTransition unmountOnExit timeout={200} key={counter}>
                {state => {
                  return counter === 0 ? (
                    <Title titleState={state}>Partners</Title>
                  ) : (
                    <Title titleState={state}>Customers</Title>
                  )
                }}
              </CSSTransition>
            </SwitchTransition>
          </Heading>
        </Wrapper>
        <Logos>
          <SwitchTransition>
            <CSSTransition unmountOnExit timeout={200} key={counter}>
              {state => {
                return logos[counter].map(el => (
                  <li key={el.id}>
                    <a href={el.url} rel='noreferrer' target='_blank'>
                      {el?.imgSrc && <Img imgState={state} src={el.imgSrc as string} alt={el?.alt} />}
                    </a>
                  </li>
                ))
              }}
            </CSSTransition>
          </SwitchTransition>
        </Logos>
      </Container>
    </StyledTrustedPartners>
  )
}

interface TitleState {
  titleState: string
}

const Title = styled.span<TitleState>`
  display: inline-block;
  transition: all 0.5s ease;
  transform: translateY(-6px);
  opacity: ${({ titleState }) => (titleState === 'entered' ? 1 : 0)};
  transform: ${({ titleState }) => (titleState === 'entered' ? 'translateY(0)' : 'translateY(6px)')};
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.grey700};
  text-transform: none;
  letter-spacing: 0;
`

interface WrapperProps {
  large?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  padding: 3rem;
  padding: ${({ large }) => large && '6rem 3rem'};

  @media all and (max-width: 36.625em) {
    padding: 3rem 1.5rem;
  }
`

const Heading = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.purple};
  letter-spacing: -0.25px;
  user-select: none;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media all and (max-width: 36.625rem) {
    font-size: 1.25rem;
  }
`

interface ImgProps {
  isLogoActive?: boolean
  imgState?: string
}

const Img = styled.img<ImgProps>`
  align-self: center;
  user-select: none;
  -webkit-user-drag: none;
  height: 3rem;
  object-fit: contain;
  width: 100%;
  transition: all 0.5s ease;
  opacity: ${({ imgState }) => (imgState === 'entered' ? 1 : 0)};
`

const Logos = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  padding: 0 48px 48px;
  grid-auto-flow: row;
  grid-template-rows: auto;
  gap: 5rem;
  justify-items: center;

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  }

  @media all and (max-width: 36.625rem) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
`

const StyledTrustedPartners = styled.section`
  background-color: ${({ theme }) => theme.colors.grey200};
  display: flex;
  position: relative;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  width: 100vw;
  z-index: 4;
  top: 0;
  left: 0;
  padding: 6rem 0;

  &.customer-stories {
    padding: 4rem 0 6rem;
    ${Wrapper} {
      padding: 0 3rem 3rem;
    }
  }
`

export default TrustedParnters
