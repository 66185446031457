import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { lighten } from 'polished';
import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';
import { customers } from '../../constants/customers';

export default () => {
  const data = useStaticQuery(graphql`
    query Testimonial {
      centre: file(relativePath: { eq: "stock/myob_contactCentre.jpg" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const myob = customers.find((x) => x.id === 'myob');

  return (
    <Testimonial id="triggerPQ">
      <Controller>
        <Scene triggerElement="#triggerPQ" classToggle="active" duration={0} reverse={false}>
          <Wrapper>
            <CopyContainer>
              <PullQuote>&quot;</PullQuote>
              <H4>
                We&apos;re invested and couldn&apos;t imagine a life without daisee. They have given us real confidence
                in elevating our quality framework to deliver consistent customer experience in 100% of interactions.
              </H4>
              <Speaker>Steve Targus&nbsp;—&nbsp;Contact Centre Manager</Speaker>
              {myob && <Company src={myob?.imgSrc as string} alt={myob.alt} />}
            </CopyContainer>
            <StyledImg fluid={data.centre.childImageSharp.fluid} alt={data.centre.name} />
          </Wrapper>
        </Scene>
      </Controller>
    </Testimonial>
  );
};

interface ImgProps {
  fluid: {};
}

const StyledImg = styled(Img)<ImgProps>`
  opacity: 0;
  transition: all 0.75s ease;
  position: relative;
  transition-delay: 0.5s;
  pointer-events: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Company = styled.img`
  height: 3rem;
  margin-top: 50px;
  opacity: 0;
  transition: all 0.75s ease;
  position: relative;
  transition-delay: 0.5s;
  pointer-events: none;
`;

const Testimonial = styled.article`
  padding: 6rem 0;
  margin: 0 1.5rem;
`;

const Wrapper = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(101deg,${lighten(0.2, theme.colors.purple)} 0%, ${theme.colors.purple} 100%)`};
  width: 100%;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  display: grid;
  grid-template-columns: 1fr 0.6fr;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
  }
  &&.active {
    h4,
    img,
    picture {
      opacity: 1;
      margin-top: 0;
    }

    p {
      opacity: 0.9;
      margin-top: 0;
    }

    ${StyledImg} {
      opacity: 1;
      margin-top: 0;
    }

    span {
      opacity: 0.2;
      margin-top: 0;
    }
  }
`;

const CopyContainer = styled.div`
  display: grid;
  gap: 3rem;
  height: min-content;
  padding: 4rem 1.5rem;
`;

const Speaker = styled.p`
  color: #fff;
  margin-top: 50px;
  opacity: 0;
  transition: all 0.75s ease;
  position: relative;
  transition-delay: 0.5s;
`;

const PullQuote = styled.span`
  color: #fff;
  opacity: 0.5;
  font-size: 6rem;
  font-style: italic;
  margin-top: 50px;
  opacity: 0;
  transition: all 0.75s ease;
  position: relative;
`;

const H4 = styled.h4`
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.3;
  max-width: 50ch;
  margin-top: 50px;
  opacity: 0;
  transition: all 0.75s ease;
  position: relative;
  transition-delay: 0.25s;
  padding-right: 1.5rem;

  @media all and (max-width: 26.563em) {
    font-size: 1.125rem;
    padding-right: 0;
    line-height: 1.5;
  }
`;
