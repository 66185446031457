import { PageProps } from 'gatsby';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { SEO } from '../SEO';
import Benefits from '../components/Benefits';
import CTA from '../components/CTA';
import Divider from '../components/Divider';
import Footer from '../components/Footer/Footer';
import HeroSection from '../components/HeroSection';
import Marquee from '../components/Marquee';
import ROICalculator from '../components/ROICalculator';
import { Site } from '../components/Site';
import Statistics from '../components/Statistics';
import { Main } from '../components/Styled';
import SuccessBanner from '../components/SuccessBanner';
import Testimonial from '../components/Testimonial';
import TrustedPartners from '../components/TrustedPartners';
import { customers, partners } from '../constants';

const IndexPage: React.FC<PageProps> = (props) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Site seo={SEO.home} {...props}>
      <Main>
        <HeroSection />
        <Statistics />
        <Divider topColor="#fff" botColor={themeContext.colors.purple} />
        <Benefits />
        <div id="roi">
          <ROICalculator />
        </div>
        <TrustedPartners />
        <SuccessBanner />
        <Marquee title="Forward-thinking businesses use daisee" list={[...partners, ...customers]} />
        <Testimonial />
        <CTA />
      </Main>
      <Footer />
    </Site>
  );
};

export default IndexPage;
